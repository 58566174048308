import PropTypes from "prop-types";
import FormField from "./FormField";
import ListField from "./ListField";
import { TextField, Select, MenuItem, InputAdornment } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useState } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

/**
 * Render a field, selects the appropriate field type to render
 */
function FieldElement({
  fieldID,
  value,
  handleChange,
  fieldConfig,
  error,
  setError,
  onBlur,
}) {
  const [datevalue, setSelectedDate] = useState(new Date());
  const {
    displayElement,
    label,
    help,
    picklistValues,
    editAllowed,
    required,
    validation,
    type,
  } = fieldConfig;
  switch (displayElement) {
    case "Text":
      return (
        <FormField
          fieldID={fieldID}
          label={label}
          helpText={help}
          required={required}
          error={error}
        >
          <TextField
            value={value}
            onChange={(e) => handleChange(fieldID, e.target.value)}
            disabled={!editAllowed}
            error={Boolean(error)}
            onBlur={() => {
              onBlur?.(fieldID, value);
            }}
          />
        </FormField>
      );

    case "Date": {
      return (
        <FormField
          fieldID={fieldID}
          label={label}
          helpText={help}
          required={required}
          error={error}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              maxDate={new Date()}
              format="dd/MM/yyyy"
              label={label}
              value={datevalue}
              onChange={(e) => {
                setSelectedDate(e);
                handleChange(fieldID, e.toLocaleDateString("en-GB"));
              }}
            />
          </MuiPickersUtilsProvider>
        </FormField>
      );
    }
    case "Link": {
      return (
        <FormField
          fieldID={fieldID}
          label={label}
          helpText={help}
          required={required}
          error={error}
        >
          <TextField
            value={value?.link}
            error={Boolean(error)}
            onChange={(e) =>
              handleChange(fieldID, {
                ...value,
                link: e.target.value,
              })
            }
            disabled={!editAllowed}
            onBlur={() => {
              onBlur?.(fieldID, value?.link);
            }}
            InputProps={getInputAdornment(type)}
          />
        </FormField>
      );
    }
    case "Select": {
      return (
        <FormField
          fieldID={fieldID}
          label={label}
          helpText={help}
          required={required}
          error={error}
        >
          <Select
            value={value ?? ""}
            onChange={(e) => handleChange(fieldID, e.target.value)}
            disabled={!editAllowed}
            error={Boolean(error)}
          >
            {picklistValues.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormField>
      );
    }
    case "MultiSelect": {
      if (validation && !error) {
        if (validation.maxLength && value?.length > validation.maxLength)
          setError(
            fieldID,
            `Please select at most ${validation.maxLength} values`
          );
      }
      return (
        <FormField
          fieldID={fieldID}
          label={fieldConfig.label}
          helpText={fieldConfig.help}
          required={required}
          error={error}
        >
          <Select
            value={value ?? []}
            onChange={(e) => {
              if (error) {
                setError(fieldID, null);
              }
              handleChange(fieldID, e.target.value);
            }}
            disabled={!fieldConfig.editAllowed}
            multiple
            error={Boolean(error)}
          >
            {fieldConfig.picklistValues.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormField>
      );
    }
    case "Textarea": {
      return (
        <FormField
          fieldID={fieldID}
          label={fieldConfig.label}
          inline={false}
          helpText={fieldConfig.help}
          required={required}
          error={error}
        >
          <TextField
            value={value}
            onChange={(e) => handleChange(fieldID, e.target.value)}
            disabled={!fieldConfig.editAllowed}
            multiline
            error={Boolean(error)}
          />
        </FormField>
      );
    }
    case "Number": {
      return (
        <FormField
          fieldID={fieldID}
          label={fieldConfig.label}
          helpText={fieldConfig.help}
          required={required}
          error={error}
        >
          <TextField
            value={value}
            onChange={(e) => handleChange(fieldID, e.target.value)}
            disabled={!fieldConfig.editAllowed}
            type="number"
            error={Boolean(error)}
          />
        </FormField>
      );
    }
    case "List": {
      return (
        <ListField
          fieldID={fieldID}
          value={value}
          fieldConfig={fieldConfig}
          key={fieldID}
          handleChange={(v) => handleChange(fieldID, v)}
          error={error}
        />
      );
    }
    case "Hidden": {
      return null;
    }
    default:
      return <pre>{JSON.stringify(fieldConfig.type, null, 4)} </pre>;
  }
}

FieldElement.propTypes = {
  fieldID: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  fieldConfig: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  /**
   * Change handler
   * @param {string} fieldID The field that was changed
   * @param value The new value
   */
  handleChange: PropTypes.func.isRequired,

  /**
   * Function to set the error state
   * @param {string} fieldID The Field ID to set the error for
   * @param {string | null} errorMessage The error message to set
   */
  setError: PropTypes.func,

  /**
   * Function called when field focus is lost
   * Use to run validations and checks
   * @param {string} fieldID The field for which focus was lost
   * @param value The value of the field
   */
  onBlur: PropTypes.func,
};

export default FieldElement;

/**
 * Returns the symbol to display at that start of the field
 * for a particular field type
 *
 * @param {string} type The field type
 * @returns {object} Material UI input props format for the adornment
 */
function getInputAdornment(type) {
  switch (type) {
    case "username":
      return {
        startAdornment: <InputAdornment position="start">@</InputAdornment>,
      };
    default:
      return null;
  }
}
