import React from 'react'
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper'
import HeaderJobPortal from './HeaderJobPortal';
import facebookLogo from "../img/fb-logo.png";
import alumniIcon from "../img/alumniIcon.png";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Button from '@material-ui/core/Button';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';

const JobDetailsContainer = styled.div`
  margin: 24px 60px;

  @media (max-width: 880px) {
    margin: 12px;
  }
  .btcp-id {
    margin: 12px 0;
    display: flex;
    .label {
      margin-right: 1ch;
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;

const Card = styled(Paper).attrs(() => ({
	elevation: 4,
}))`
	&.MuiPaper-rounded {
		border-radius: 8px;
	}
	padding: 16px;
    margin: 2%;

    .project-title {
		font-weight: bold;s
		font-size: 16px;
		color: rgba(0, 0, 0, 0.7);
	}

	.project-initiated {
		font-size: 12px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
	}

	.project-info {
		font-size: 14px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.7);
		margin-top: 20px;
		.MuiSvgIcon-fontSizeSmall {
			font-size: 14px;
		}
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
    
    .contactBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 3%;
      }

	.project-summary {
		font-size: 14px;
		line-height: 2px;
		color: rgba(0, 0, 0, 0.7);
		height: calc(5 * 20px);
		overflow: hidden;
		text-overflow: ellipsis;
	}
`

const CardDiv = styled.div`
    display: flex;
    align-items: center;
    
`

const JobContent = styled.div`
    display: flex;
    align-items: baseline;
`

const JobDetail = () => {
    const location = useLocation();
    const job = location.state.params;
    return (
      <div>
        <HeaderJobPortal />
        <JobDetailsContainer>
            <h3>{"Job Details"}</h3>
              <Card>
                    <CardDiv>
                      <div style={{display: "flex", width: "180%"}}>
                        <img
                            src={facebookLogo}
                            style={{width: "fir-content"}}
                        />
                        <div style={{paddingLeft:"3%", width:"fit-content"}}>
                            <div className='project-initiated'>{job.organisationName}</div>
                            <h3 style={{marginTop:"auto"}}><u>{job.jobDescription}</u></h3>
                            <div style={{display:"flex", justifyContent: "space-between"}}>
                                <JobContent className='project-initiated' style={{alignItems: "center"}}><LocationOnOutlinedIcon />{job.cities}</JobContent>
                            </div>
                            <JobContent className='project-initiated' style={{alignItems: "center", justifyContent: "space-between", marginTop: "5%"}}>
                              <Button variant="contained" color="secondary">
                                Apply
                              </Button>
                              <BookmarkBorderOutlinedIcon />
                              <ShareOutlinedIcon />
                            </JobContent>
                            
                        </div>
                      </div>
                      <div style={{width:"fit-content"}}>
                            <h4>Job Posted By:</h4>
                            <div style={{display: "flex", justifyContent: "start", marginTop: "-5%"}}>
                              <img
                                src={alumniIcon}
                                width="10%"
                              />
                              <div>
                                <h5 style={{marginBlock: "auto"}}>{job.employerFirstName + ' ' + job.employerLastName}</h5>
                                <div className='project-initiated'>{job.emplayerEmail}</div>
                              </div>

                            </div>
                      </div>
                    </CardDiv>
                    <CardDiv style={{ alignItems: "baseline", justifyContent: "space-around"}}>
                      <div style={{display: "flex", width: "72%", marginTop: "3%"}}>
                        <div>
                          <h3>Company Description</h3>
                          <p className='project-initiated'>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h4 style={{marginBlock: "auto"}}>Joining On</h4>
                          <div className='project-initiated'>{job.joiningDate}</div>
                        </div>
                        <div style={{marginTop: "5%"}}>
                          <h4 style={{marginBlock: "auto"}}>Pathway</h4>
                          <div className='project-initiated'>{job.pathwayCategorization}</div>
                        </div>
                        <div style={{marginTop: "5%"}}>
                          <h4 style={{marginBlock: "auto"}}>Salary</h4>
                          <div className='project-initiated'>{job.maxSalary}</div>
                        </div>
                        <div style={{marginTop: "5%"}}>
                          <h4 style={{marginBlock: "auto"}}>Employement Type</h4>
                          <div className='project-initiated'>{"Full Time"}</div>
                        </div>

                      </div>
                    </CardDiv>
                  </Card>
        </JobDetailsContainer>
      </div>
    )
}

export default JobDetail;