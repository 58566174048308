import { initSearchClient } from "../algoliasearch";
import { useQuery } from "react-query";

function fetchProfile(email) {
  const algoliaIndex = initSearchClient();
  return algoliaIndex.search("", {
    filters: `tfiEmail:${email}`,
    typoTolerance: false,
    responseFields: ["hits"],
  });
}


/**
 * Fetches the data of a single Profile by ID
 * @param {string} email The Profile ID to fetch
 */
const useFetchSingleProfileEmail = (email) => {
  const singleProfileQuery = useQuery(
    ["singleProfile", email],
    () => fetchProfile(email),
    {
      staleTime: 10 * 60 * 1000,
      enabled: true,
    }
  );
  return singleProfileQuery;
};
export default useFetchSingleProfileEmail;
