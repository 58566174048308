import JobSummaryCard from './JobSummaryCard';
import HeaderJobPortal from './HeaderJobPortal';
import styled from 'styled-components'


const PageContainer = styled.main`
	margin: 2vh 2vw;
	@media (max-width: 880px) {
		margin: 2vh 2vw;
	}
`

const JobPortal = () => {

	return (
		<div>
            <HeaderJobPortal />
            <PageContainer>
                <JobSummaryCard />
            </PageContainer>
			
		</div>
	)
}
export default JobPortal;