import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import {LocationOn, TrendingUp} from '@material-ui/icons'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import color from "../helpers/colorTheme"
import {DomainList} from '../helpers/textComponents'

const Card = styled(Paper).attrs(() => ({
	elevation: 4,
}))`
	&.MuiPaper-rounded {
		border-radius: 8px;
	}
	padding: 16px;
	overflow: hidden;

	.project-title {
		font-weight: bold;s
		font-size: 16px;
		color: rgba(0, 0, 0, 0.7);
	}

	.project-initiated {
		font-size: 12px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
	}

	.project-info {
		font-size: 14px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.7);
		margin-top: 20px;
		.MuiSvgIcon-fontSizeSmall {
			font-size: 14px;
		}
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
    
    .contactBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 3%;
      }

	.project-summary {
		font-size: 14px;
		line-height: 20px;
		color: rgba(0, 0, 0, 0.7);
		margin-top: 12px;
		height: calc(5 * 20px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre-line;
	}
`
const SmallDomainList = styled(DomainList).attrs(() => ({
	fontSize: '12px',
}))`
	margin: 16px 0 0 0;
`

const ProfileSummaryCard = ({data}) => {

	return (
		<Card>
		 <div className="project-title">{data.fullName}</div>
			<div className="project-initiated">{data.cohortYear}</div>
            {data.currentCompanyPuzzlePiece ?
			 <SmallDomainList>
						<div className="domain" >
							{data.currentCompanyPuzzlePiece}
						</div>
			</SmallDomainList>  : null}
            <div className="project-info">
				<div className="project-location">
					<LocationOn fontSize="small" />{' '}
					  {data.currentCityIndia}
				</div>
                {data.currentSector ? 
				<div className="project-status">
					<TrendingUp fontSize="small" />{' '}
					{data.currentSector}
				</div> : null }
			</div>
            <div className="contactBox">
            {data.facebookLink === "" ?  null : <a href={`https://www.facebook.com/${data.facebookLink}`}><FacebookIcon style={{color:color.primaryBlue}} /></a>}  
            {data.linkedInLink === "" ?  null : <a href={`${data.linkedInLink}`} ><LinkedInIcon style={{color:color.primaryBlue}}/></a> }  
            {data.twitterLink === "" ?  null :  <a href={`https://twitter.com/${data.twitterLink}`} ><TwitterIcon style={{color:color.primaryBlue}}/></a> }  
             <a href={`mailto:${data.email}`}><MailOutlineIcon style={{color:color.primaryBlue}}/></a>
           </div>
		</Card>
	)
}


export default ProfileSummaryCard;
