import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
const SplashScreenContainer = styled.div`
  background-color: #263238;
  width: 100vw;
  height: 100vh;
  color: white;
`;

const ChildContainer = styled.div`
  height: 56px;
  position: absolute;
  bottom: 20%;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const TitleContainer = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
  text-align: center;
  .MuiTypography-h1 {
    color: white;
    font-size: 3rem;
    text-transform: none;
    font-weight: normal;
  }
`;

/**
 * Renders a splash screen
 */
const Splash = ({ children }) => {
  return (
    <SplashScreenContainer>
      <TitleContainer>
        <Typography variant="h1">Alumni Portal</Typography>
      </TitleContainer>
      <ChildContainer>{children}</ChildContainer>
    </SplashScreenContainer>
  );
};

export default Splash;
