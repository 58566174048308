import styled from 'styled-components'
export const DomainList = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	& > .domain {
		background-color: rgba(255, 217, 68, 0.2);
		color: #8b572a;
		margin: 0 10px 4px 0;
		padding: 0.4em;
		font-size: ${({fontSize}) => fontSize ?? '17px'};
		font-weight: 600;
		border-radius: 4px;
		cursor: pointer;
	}
`
