import {useCallback, useReducer, useState} from 'react'
import schema from './schema.json'
import set from 'lodash/set'
import get from 'lodash/get'

function profileStateReducer(state, action) {
	switch (action.type) {
		case 'RECEIVE_SERVER_STATE': {
			return action.data
		}
		case 'UPDATE_FIELD': {
			const {fieldID, value} = action
			let o = {...state}
			set(o, fieldID, value)
			return o
		}
		default:
			return state
	}
}

/**
 * Get the default blank value for a specific field type
 * @param {string} fieldType The type of field
 * @returns appropriate blank value
 */
function getDefaultValue(fieldType) {
	let value
	switch (fieldType) {
		case 'picklist':
		case 'string':
		case 'textarea':
		case 'url':
		case 'email':
		case "Date":
		case 'double':
			value = ''
			break
		case 'multipicklist':
		case 'List':
			value = []
			break
		default:
			value = null
	}
	return value
}

/**
 * Gets the schema object of the specified field
 * @param {string} fieldID The field ID
 * @returns {object} The field schema
 */
function getSchemaConfig(fieldID) {
	const keyFragments = fieldID.split('.')
	if (keyFragments.length === 1) return schema.schema[fieldID]
	else {
		const fieldConfig = getSchemaConfig(keyFragments[0])
		if (fieldConfig.type === 'Object')
			return fieldConfig.schema[keyFragments[1]]
	}
}

/**
 * Returns an updated object with the specified key/value set, without mutation
 * @param {object} errors The current error state
 * @param {string | Array.<string>} fieldID The field ID to update
 * @param {string} message Error message
 * @returns {object} The updated error object
 */
function getUpdateErrorObject(errors, fieldID, message) {
	let errorState = {...errors}
	set(errorState, fieldID, message)
	return errorState
}

/**
 * Returns initial state object with appropriate blank values for every field
 * @returns initialState
 */
function initialiseProfileState() {
	const {keys} = schema
	let initialState = {}
	keys.forEach(key => {
		let value
		const type = schema.schema[key].type
		value = getDefaultValue(type)

		initialState[key] = value
	})
	return initialState
}

function useProfileState() {
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
	const [errors, setErrors] = useState({})
	const [profileState, dispatch] = useReducer(
		profileStateReducer,
		{},
		initialiseProfileState
	)

	/**
	 * Set up project state from exisiting data
	 * @param {object} data the project data, following the format in the schema file
	 */
	const setInitialData = useCallback(data => {
		dispatch({type: 'RECEIVE_SERVER_STATE', data})
	}, [])

	/**
	 * Updates project state with new value
	 * @param {string} fieldID The field to change
	 * @param value The value to set
	 */
	const handleChange = useCallback(
		(fieldID, value) => {
			setErrors(e => {
				if (get(e, fieldID))
					return getUpdateErrorObject(e, fieldID, null)
				else return e
			})
			dispatch({type: 'UPDATE_FIELD', fieldID, value})
			if (!hasUnsavedChanges) setHasUnsavedChanges(true)
		},
		[hasUnsavedChanges]
	)

	/**
	 * Set/Remove field error message
	 * @param {string} fieldID The field to update the error message
	 * @param {string | null} message The message text to update. set to `null` to clear error
	 */
	const updateError = useCallback((fieldID, message) => {
		setErrors(e => ({
			...e,
			[fieldID]: message,
		}))
	}, [])

	/**
	 * Check whether all mandatory fields are filled.
	 * @modifies errors
	 * @returns {boolean} Whether any required fields are blank
	 */
	function checkRequired() {
		let hasErrors = false
		schema.keys.forEach(key => {
			const fieldConfig = schema.schema[key]
			if (fieldConfig.type === 'List') {
			}
			if (fieldConfig.type === 'Object') {
			} else {
				if (
					fieldConfig.required &&
					profileState[key] === getDefaultValue(fieldConfig.type)
				) {
					setErrors(state => ({
						...state,
						[key]: 'This field is required',
					}))
					hasErrors = true
				}
			}
		})
		return hasErrors
	}

	/**
	 * Check for field specific errors
	 * @param {string} fieldID The field to check for
	 * @param value the field value to check against
	 */
	const checkFieldError = useCallback((fieldID, value) => {
		const fieldConfig = getSchemaConfig(fieldID)
		if (!fieldConfig || !value?.length > 0) return

		if (fieldConfig.type === 'url') {
			// Validate URL format
			// eslint-disable-next-line no-useless-escape
			const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
			const isValidURL = urlRegex.test(value)
			if (!isValidURL) {
				setErrors(state =>
					getUpdateErrorObject(
						state,
						fieldID,
						'Please enter a complete and valid URL. e.g. https://teachforindia.org'
					)
				)
			}
		} else if (fieldConfig.type === 'username') {
			let errorMessage = null
			if (value.includes('.com/')) {
				errorMessage =
					'Please enter only your username, the link will be generated automatically'
			} else if (value.startsWith('@')) {
				errorMessage =
					'Please enter only your username, the @ symbol is added automatically'
			}
			if (errorMessage) {
				setErrors(state =>
					getUpdateErrorObject(state, fieldID, errorMessage)
				)
			}
		}
	}, [])

	/**
	 * Checks for form errors
	 * @todo Check for field specific errors
	 * @returns {boolean} Whether the form has any errors
	 */
	function checkHasErrors() {
		return (
			checkRequired() || Object.values(errors).filter(Boolean).length > 0
		)
	}

	/**
	 * @modifies hasUnsavedChanges
	 */
	function markChangesSaved() {
		setHasUnsavedChanges(false)
	}

	return {
		profileState,
		setInitialData,
		errors,
		updateError,
		checkHasErrors,
		checkFieldError,
		handleChange,
		hasUnsavedChanges,
		markChangesSaved,
	}
}
export default useProfileState
