import React, {useState} from 'react'
import {Link,  useLocation, Route, Redirect} from 'react-router-dom'
import styled from 'styled-components'
import forEach from 'lodash/forEach'
import shuffle from 'lodash/shuffle'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import MuiLink from '@material-ui/core/Link'

import MoreIcon from '@material-ui/icons/ChevronRight'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Header from './Header'
import ProfileSummaryCard from './ProfileSummaryCard'
import data from '../data/profile.json'


const shuffleProjects = data => {
	const {groupedHits, facets} = data
	const shuffledHits = {}
	forEach(groupedHits, (groupedValues, groupKey) => {
		shuffledHits[groupKey] = {}
		forEach(groupedValues, (array, key) => {
			shuffledHits[groupKey][key] = shuffle(array)
		})
	})
	return {
		shuffledHits,
		facets,
	}
}

const PageContainer = styled.main`
	margin: 2vh 2vw;
	@media (max-width: 880px) {
		margin: 2vh 2vw;
	}
`

const GroupingControlBar = styled(Toolbar).attrs(() => ({
	disableGutters: true,
}))`
	&.MuiToolbar-root {
		justify-content: space-between;
	}
`

const StyledTabs = styled(Tabs)`
	.MuiTab-root {
		font-size: 1.1rem;
	}
	margin: 2rem 0;

	@media (min-width: 768px) {
		.MuiTab-root {
			font-size: 1.3rem;
		}
	}

	@media (max-width: 375px) {
		.MuiTab-root {
			font-size: 0.88rem;
		}
	}
`

const CitySeparator = styled.div`
	margin-bottom: 24px;
	h1,
	h2,
	h3 {
		margin-bottom: 0;
		margin-right: 24px;
	}
	h2,
	h3 {
		color: rgba(0, 0, 0, 0.7);
		font-weight: 600;
		font-size: 24px;
	}
	h3 {
		font-size: 16px;
	}
	hr {
		height: 1px;
		background-color: #979797;
		border: none;
	}
	.city-title {
		display: flex;
		align-items: baseline;
		h2 {
			text-transform: capitalize;
		}
	}
	.MuiSvgIcon-root {
		vertical-align: bottom;
	}
`

export const ProjectCardsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	flex-wrap: nowrap;
	& > * {
		margin-right: 24px;
		margin-bottom: 0;
		flex: 350px 1 0;
		/* width: 350px; */
		@media (max-width: 880px) {
			margin-right: 0;
			margin-bottom: 12px;
			width: 85vw;
		}
	}
`


const Home = () => {

  
	const [selectedFacet, setSelectedFacet] = useState('currentCityIndia')
	const {shuffledHits, facets} = shuffleProjects(data)
	const location = useLocation();

	const matchesMD = useMediaQuery(theme => theme.breakpoints.up('md'))
	const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'))
	const matchesXL = useMediaQuery(theme => theme.breakpoints.up('xl'))

	let maxCardCount = 1
	if (matchesMD) maxCardCount++
	if (matchesLG) maxCardCount++
	if (matchesXL) maxCardCount++


	const facetOptions = [
		{
			name: 'currentCityIndia',
			label: 'CurrentCity',
		},
		{
			name: 'currentCompanyPuzzlePiece',
			label: 'Pathways',
		},
	]

	const selectedFacetsData = facets[selectedFacet]

	return (
		<div>
			<Header />
			<PageContainer>
				<StyledTabs
					// value={selectedTab}
					// variant="scrollable"
				>
					<Tab label="Explore Alumni " />
				</StyledTabs>
				<Route exact path="/">
					<Redirect
						to={{pathname: '/', search: location.search}}
					/>
				</Route>
				<Route path="/">
					<GroupingControlBar>
						<div>
							Group Alumni  by:{' '}
							<ButtonGroup variant="outlined">
								{facetOptions.map(option => (
									<Button
										color={
											selectedFacet === option.name
												? 'primary'
												: 'default'
										}
										onClick={() =>
											setSelectedFacet(option.name)
										}
										key={option.name}
									>
										{option.label}
									</Button>
								 ))}
							</ButtonGroup>
						</div>
					</GroupingControlBar>
					{Object.keys(selectedFacetsData).map(facetKey => {
						return (
							<React.Fragment >
								<CitySeparator>
									<div className="city-title">
										<h2>{facetKey}</h2>
										<MuiLink
											component={Link}
											to={{
												pathname: '/search',
												search: `?refinementList[${encodeURIComponent(
													selectedFacet
												)}][0]=${encodeURIComponent(
													facetKey
												)}`,
											}}
										>
											view all alumni <MoreIcon />
										</MuiLink>
									</div>
									<hr />
								</CitySeparator>
                          <ProjectCardsContainer>					       
									{ shuffledHits?.[selectedFacet][facetKey]?.slice(0,maxCardCount)?.map(profile => {
											return (
										
												<Link
													to={`/profile/${profile.objectID}`}
													key={profile.objectID}
													style={{display:"block"}}
												>
													<ProfileSummaryCard
														data={profile}
													></ProfileSummaryCard>
												 </Link>
											)
										})}
								</ProjectCardsContainer>
							</React.Fragment>
					   	 )
				  })} 
				</Route>
			</PageContainer>
		</div>
	)
}
export default Home;



