import React, {useState} from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import data from '../data/jobs.json'
import { useHistory} from 'react-router-dom';
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import facebookLogo from "../img/fb-logo.png";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';

const SearchBoxContainer = styled(Paper).attrs(() => ({
    elevation: 0,
    component: "form",
  }))`
    margin: 0 auto;
    padding: 4px 8px;
    width: 30vw;
    min-width: 300px;
    box-sizing: border-box;
  
    .MuiOutlinedInput-input {
      padding: 12px 21.5px;
    }
  
    .MuiOutlinedInput-root {
      border-radius: 21.5px;
    }
  
    @media (max-width: 880px) {
      width: 100vw;
    }
  `;

const CardContainer = styled.div`
    width: 50%;
    flex-grow: 1;
`

const MainContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const JobContent = styled.div`
    display: flex;
    align-items: center;
`

const SearchDiv = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`
const CardDiv = styled.div`
    display: flex;
    align-items: center;
    
`

const Card = styled(Paper).attrs(() => ({
	elevation: 4,
}))`
	&.MuiPaper-rounded {
		border-radius: 8px;
	}
	padding: 16px;
    margin: 2%;

    .project-title {
		font-weight: bold;s
		font-size: 16px;
		color: rgba(0, 0, 0, 0.7);
	}

	.project-initiated {
		font-size: 12px;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.5);
	}

	.project-info {
		font-size: 14px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.7);
		margin-top: 20px;
		.MuiSvgIcon-fontSizeSmall {
			font-size: 14px;
		}
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
    
    .contactBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 3%;
      }

	.project-summary {
		font-size: 14px;
		line-height: 2px;
		color: rgba(0, 0, 0, 0.7);
		height: calc(5 * 20px);
		overflow: hidden;
		text-overflow: ellipsis;
	}
`

const JobSummaryCard = () => {
    const [filteredData, setFilteredData] = useState([]);
    const history = useHistory();

    const toJobDetail = (e,job) => {
        history.push('/jobDetail',{params:job})
    }
    const searchJobs = (input) => {
        const keyword = input.toLowerCase();
        const filteredJob = data.filter(function(job){
            const jobDes = job.jobDescription.toLowerCase();
            return jobDes.indexOf(keyword) > -1; 
         });
         setFilteredData(filteredJob);   
    }
	return (
        <div>
            <SearchDiv>
            <h3>Explore Jobs</h3>
            <SearchBoxContainer
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                >
                <TextField
                    variant="outlined"
                    placeholder="Search Jobs"
                    onChange={(e) => searchJobs(e.target.value)}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <SearchIcon />
                        </InputAdornment>
                    ),
                    }}
                    fullWidth
                />
                </SearchBoxContainer>
            </SearchDiv>
            
		<MainContainer>
            { filteredData.length > 0 ? 
                filteredData.map((job) => {
                    return <CardContainer>
                        <a onClick={(e)=>{toJobDetail(e,job)}}>
                            <Card>
                                <CardDiv>
                                    <img
                                        src={facebookLogo}
                                        width="14%"
                                    />
                                    <div style={{paddingLeft:"3%"}}>
                                        <div className='project-initiated'>{job.organisationName}</div>
                                        <h3 style={{marginTop:"auto"}}><u>{job.jobDescription}</u></h3>
                                        <div style={{display:"flex", justifyContent: "space-between"}}>
                                            <JobContent className='project-initiated'><LocationOnOutlinedIcon />{job.cities}</JobContent>
                                            <JobContent className='project-initiated'><QueryBuilderOutlinedIcon />{job.joiningDate}</JobContent>
                                            <JobContent className='project-initiated'><WorkOutlineOutlinedIcon />Full Time</JobContent>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: "auto"}}>
                                        <BookmarkBorderOutlinedIcon />
                                        <ShareOutlinedIcon />
                                    </div>
                                </CardDiv>
                            </Card>
                        </a>  
                    </CardContainer>
                }) :
            
            data.map((job) => {
                return <CardContainer>
                    <a onClick={(e)=>{toJobDetail(e,job)}}>
                        <Card>
                            <CardDiv>
                                <img
                                    src={facebookLogo}
                                    width="14%"
                                />
                                <div style={{paddingLeft:"3%"}}>
                                    <div className='project-initiated'>{job.organisationName}</div>
                                    <h3 style={{marginTop:"auto"}}><u>{job.jobDescription}</u></h3>
                                    <div style={{display:"flex", justifyContent: "space-between"}}>
                                        <JobContent className='project-initiated'><LocationOnOutlinedIcon />{job.cities}</JobContent>
                                        <JobContent className='project-initiated'><QueryBuilderOutlinedIcon />{job.joiningDate}</JobContent>
                                        <JobContent className='project-initiated'><WorkOutlineOutlinedIcon />Full Time</JobContent>
                                    </div>
                                </div>
                                <div style={{marginLeft: "auto"}}>
                                    <BookmarkBorderOutlinedIcon />
                                    <ShareOutlinedIcon />
                                </div>
                            </CardDiv>
                        </Card>
                    </a>  
                </CardContainer>
            })}
		    
		</MainContainer>
        </div>
	)
}


export default JobSummaryCard;
