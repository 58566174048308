import styled from 'styled-components'
import PropTypes from 'prop-types'

const FormFieldContainer = styled.div`
	margin: 24px 0;
	.field {
		display: grid;
		grid-template-columns: ${({inline}) =>
			inline ? '1fr minmax(0, 2fr)' : '1fr'};
		width: 700px;
		max-width: 90vw;
		align-items: center;
	}

	.help {
		color: #666666;
		font-size: 0.9rem;
		width: 700px;
		max-width: 90vw;
	}
	.error {
		color: red;
		font-size: 0.9rem;
		width: 700px;
		max-width: 90vw;
	}

	label {
		margin-right: 24px;
	}
`

/**
 * Presentational component to arrange a form field
 * Contains the label, field, help text, and error message
 */
function FormField({
	fieldID,
	label,
	children,
	inline,
	helpText,
	required,
	error,
}) {
	return (
		<FormFieldContainer inline={inline}>
			<div className="field">
				<label
					htmlFor={fieldID}
					className={required ? 'required' : null}
				>
					{label}
				</label>
				{children}
			</div>
			{helpText && <div className="help">{helpText}</div>}
			{error && <div className="error">{error}</div>}
		</FormFieldContainer>
	)
}
FormField.defaultProps = {
	inline: true,
}
FormField.propTypes = {
	fieldID: PropTypes.string,
	label: PropTypes.string,

	// The field element
	children: PropTypes.node,

	// Whether to show the field and label in the same line or not
	inline: PropTypes.bool,

	helpText: PropTypes.string,
	required: PropTypes.bool,

	// Error message if the field is in an error state
	error: PropTypes.string,
}

export default FormField
