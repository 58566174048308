import styled from "styled-components";
import PropTypes from "prop-types";
import FieldElement from "./FieldElement";

import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/HighlightOff";

const ListElementContainer = styled.div`
  margin-top: 12px;
  .MuiCard-root {
    margin: 16px 8px 16px 16px;
  }
  .MuiCardActions-root {
    justify-content: flex-end;
  }
  .list-actions {
    display: flex;
    justify-content: center;
  }
`;

/**
 * Renders a List Field
 */
function ListField({ fieldID, value, handleChange, fieldConfig }) {
  const { label, keys, schema, required } = fieldConfig;

  /**
   * Handle changes in list elements
   * @param {string} fieldID unused
   * @param {Number} index array index of the element being edited
   * @param {string} key Key of the field in the element being edited
   * @param v value
   */
  function handleListChange(fieldID, index, key, v) {
    const _value = [...value];
    const fieldObject = { ...value[index] };
    fieldObject[key] = v;
    _value.splice(index, 1, fieldObject);
    handleChange(_value);
  }

  /**
   * Add a new empty list item
   */
  function handleAddListItem() {
    const _value = value ? [...value] : [];
    const fieldObject = {};
    keys.forEach((key) => {
      if (
        schema[key].type === "picklist" &&
        schema[key].picklistValues.length === 1
      ) {
        fieldObject[key] = schema[key].picklistValues[0].value;
      } else {
        fieldObject[key] = "";
      }
    });
    _value.push(fieldObject);
    handleChange(_value);
  }

  /**
   * Mark a list item for deletion
   * @param {Number} index The index of the element to remove
   */
  function handleRemoveListItem(index) {
    const _value = [...value];
    _value.splice(index, 1);
    console.log(_value);
    handleChange(_value);
  }

  return (
    <ListElementContainer>
      <Typography variant="h5" className={required ? "required" : null}>
        {label}
      </Typography>
      {value?.map((listItem, index) =>
        listItem._action !== "DELETE" ? (
          <Card variant="outlined" key={index}>
            <CardContent>
              {keys.map((key) =>
                schema[key].display ? (
                  <FieldElement
                    fieldID={`${fieldID}${key}`}
                    key={`${fieldID}${key}`}
                    value={listItem[key]}
                    fieldConfig={schema[key]}
                    handleChange={(id, value) => {
                      handleListChange(fieldID, index, key, value);
                    }}
                  />
                ) : null
              )}
            </CardContent>
            <CardActions>
              <Button
                onClick={() => handleRemoveListItem(index)}
                endIcon={<DeleteIcon />}
                size="small"
                variant="contained"
                color="primary"
              >
                Remove
              </Button>
            </CardActions>
          </Card>
        ) : null
      )}
      <div className="list-actions">
        <Button
          onClick={handleAddListItem}
          endIcon={<AddIcon />}
          variant="contained"
          color="secondary"
        >
          Add
        </Button>
      </div>
    </ListElementContainer>
  );
}

ListField.propTypes = {
  fieldID: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,

  /**
   * Change handler
   * @param value The new value
   */
  handleChange: PropTypes.func.isRequired,

  // Configuration object of the List Field
  fieldConfig: PropTypes.object.isRequired,
};

export default ListField;
