import algoliasearch from "algoliasearch";

let appID = process.env.REACT_APP_ALGOLIA_APP_ID;
let searchApiKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
export const indexName = process.env.REACT_APP_INDEX_NAME;

export const getSearchClient = () => {
  return algoliasearch(appID, searchApiKey);
};

export const initSearchClient = () => {
  const client = getSearchClient();
  const index = client.initIndex(indexName);

  return index;
};
