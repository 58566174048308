import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Loading from "./components/Loading";
import Home from "./components/Home";
import JobPortal from "./components/JobPortal";
import JobDetail from "./components/JobDetail";
import Login from "./components/Login";
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles'
import AlumniDetail from "./components/Alumni/AlumniDetail";
import { AuthProvider } from "./helpers/authContext";
import ErrorBoundary from "./ErrorBoundaries/ErrorBoundary";
import Search from "./components/Search";
import Editor from "./components/AlumniEditor/Editor";


const theme = createTheme({
	palette: {
		primary: {
			main: '#8b572a',
		},
		secondary: {
			main: '#19c6a4',
		},
	},
	typography: {
		fontFamily: ['"Open Sans"', 'sans-serif'].join(','),
	},
	props: {
		MuiTextField: {
			variant: 'outlined',
		},
		MuiSelect: {
			variant: 'outlined',
		},
		MuiInput: {
			fullWidth: true,
		},
	},
	overrides: {
		MuiTypography: {
			h1: {
				fontSize: '1.8rem',
				color: 'rgba(0, 0, 0, 0.5)',
				fontWeight: 'bold',
				textTransform: 'uppercase',
				margin: '18px 0',
			},
			h2: {
				fontSize: '1.6rem',
				color: 'rgba(0, 0, 0, 0.5)',
				fontWeight: 'bold',
				textTransform: 'uppercase',
				margin: '12px 0',
			},
			h5: {
				fontWeight: 'bold',
				fontSize: '16px',
				color: 'rgba(0, 0, 0, 0.7)',
				textTransform: 'uppercase',
			},
		},
	},
})
const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <MuiThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
				<Route exact path="/jobs">
                  <JobPortal />
                </Route>
				<Route exact path="/jobDetail">
                  <JobDetail />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route path="/profile/:profileID">
                  <AlumniDetail />
                </Route>
                <Route path="/search">
                  <Search />
                </Route>               
                <Route path="/new">
                  <Editor />
                </Route>
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </AuthProvider>
      </QueryClientProvider>
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
