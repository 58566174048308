/* global */
import React from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Divider from "@material-ui/core/Divider";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Button from "@material-ui/core/Button";
import Header from "../Header";

import { DomainList } from "../../helpers/textComponents";
import color from "../../helpers/colorTheme";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

import twitterLogo from "../../img/tw-logo.png";
import facebookLogo from "../../img/fb-logo.png";

//alumni
import useFetchSingleProfile from "../../helpers/hooks/useFetchSingleProfile";

const AlumniDetailsContainer = styled.div`
  margin: 24px 60px;

  @media (max-width: 880px) {
    margin: 12px;
  }
  .btcp-id {
    margin: 12px 0;
    display: flex;
    .label {
      margin-right: 1ch;
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-main {
    display: flex;
    align-items: baseline;
    gap: 12px;
  }
`;

const Title = styled.h1`
  font-size: 2em;
`;
const Body = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-top: 40px;
  grid-gap: 40px;

  .column {
    & > * {
      margin-bottom: 24px;
    }
  }
  .footer {
    grid-column: 1/3;
    margin: 0 auto;
  }

  @media (max-width: 880px) {
    margin-top: 0;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 0;
    .left {
      grid-row: 2;
    }
    .right {
      grid-row: 1;
    }
  }
`;

const DetailCard = styled(Card).attrs(() => ({
  elevation: 2,
}))`
  padding: 32px;
  .MuiCardContent-root {
    padding: 0;
  }
  &.MuiPaper-rounded {
    border-radius: 16px;
  }
  .text {
    font-size: 16px;
    white-space: pre-line;
  }
  .small.text {
    font-size: 16px;
  }
  .budget {
    font-size: 28px;
    font-weight: 600;
    &.raised {
    }
    &.budget-required {
      color: #6e6e6e;
    }
  }
  @media (max-width: 880px) {
    padding: 4px;
    .MuiCardContent-root {
      padding: 18px;
    }
  }
`;
const BasicCard = styled(DetailCard)`
  .MuiCardContent-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    #alumni-summary {
      grid-column: 1/3;
    }
    @media (max-width: 880px) {
      grid-template-columns: 1fr;
      #alumni-summary {
        grid-column: 1/2;
      }
    }
  }
`;

const Label = styled(Typography).attrs(() => ({
  variant: "h5",
}))`
  &.MuiTypography-h5 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
  }
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  margin-top: 20px;

  @media (max-width: 880px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
    & > *:nth-child(2n) {
      margin: 0 0 20px 0;
    }
  }
`;

const OtherDetailContainer = styled.div`
  display: flex;
  & > div {
    margin: 0 16px;
  }
`;

const FreeLancerBox = styled.div`
  color: black;
  font-weight: bold;
  .other-count {
    font-size: 18px;
  }
  .other-label {
    font-size: 18px;
  }
`;

const SocialLogosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  .MuiButton-startIcon > img {
    height: 1em;
    object-fit: contain;
  }
  .MuiButton-root {
    margin: 12px;
    border-radius: 8px;
  }
`;

const AlumniDetail = () => {
  const { profileID } = useParams();

  const {
    isLoading: isProfileLoading,
    data: fetchedData,
  } = useFetchSingleProfile(profileID);
  if (isProfileLoading) {
    return <div>loading..</div>;
  }
  const data = fetchedData.hits[0];

  return (
    <>
      <Header />
      <AlumniDetailsContainer>
        <Breadcrumbs separator={<NavigateNextIcon />}>
          <MuiLink color="inherit" to="/" component={Link}>
            <Typography>Alumni Details</Typography>
          </MuiLink>
          <Typography color="textPrimary">{data.fullName}</Typography>
        </Breadcrumbs>

        <TitleContainer>
          <div className="title-main">
            <Title>{data.fullName}</Title>
          </div>
        </TitleContainer>

        {data.currentCompanyPuzzlePiece ? (
          <DomainList>
            <MuiLink
              to={{
                pathname: "/search",
                search: `?refinementList[currentCompanyPuzzlePiece][0]=${encodeURIComponent(
					data.currentCompanyPuzzlePiece
                )}`,
              }}
              className="domain"
              component={Link}
			  key={data.currentCompanyPuzzlePiece}
            >
              {data.currentCompanyPuzzlePiece}
            </MuiLink>
          </DomainList>
        ) : null}

        <div className="btcp-id">
          <Typography variant="subtitle2" className="label">
            Cohort Year :
          </Typography>
          <Typography variant="subtitle2" className="alumni-id">
            {data.cohortYear}
          </Typography>
        </div>

        <Body>
          <div className="column left">
            {/* Summary Card */}
            <BasicCard>
              <CardContent>
                <div>
                  <Label>Alumni Professional Status</Label>
                  <div className="text">{data.alumniProfessionalStatus}</div>
                </div>
                <div>
                  <Label>Current City</Label>
                  <MuiLink
                    className="text"
                    to={{
                      pathname: "/search",
                    }}
                    component={Link}
                  >
                    {data.currentCityIndia + "," + data.currentCountryOrRegion}
                  </MuiLink>
                </div>
                <div id="alumni-summary">
                  <Label>Current Company Designation</Label>
                  <div className="text">{data.currentCompanyDesignation}</div>
                </div>
              </CardContent>
            </BasicCard>

            {/* Impact Card */}
            <DetailCard>
              <CardContent>
                <div>
                  <Label>Current Company Organization Name</Label>
                  <div className="small text">
                    {data.currentCompanyOrganizationName}
                  </div>
                </div>
                <br />
                <div>
                  <Label>Current Company If Other</Label>
                  <div className="small text">{data.currentCompanyIfOther}</div>
                </div>
                <br />
                <div>
                  <Label>Current Company Level</Label>
                  <div className="small text">{data.currentCompanyLevel}</div>
                </div>
                <br />

                <Divider />

                <DetailGrid>
                  <Label>Freelancer Nature of Work</Label>
                  <OtherDetailContainer>
                    <FreeLancerBox variant="direct">
                      <div className="other-count">
                        {data.freelancerNatureOfWork}
                      </div>
                    </FreeLancerBox>
                  </OtherDetailContainer>
                  <Label>Actively looking for job opportunities</Label>
                  <OtherDetailContainer>
                    <FreeLancerBox variant="indirect">
                      <div className="other-count">
                        {data.lookingForJobOpportunities === 0 ? "No" : "Yes"}
                      </div>
                    </FreeLancerBox>
                  </OtherDetailContainer>
                </DetailGrid>
              </CardContent>
            </DetailCard>
            {/* Support Card */}
            <DetailCard>
              <CardContent>
                <DetailGrid>
                  <Label> Current College Organization Name</Label>
                  <div className="text">
                    {data.currentCollegeOrganizationName}
                  </div>

                  <Label>Current College If Other</Label>
                  <div className="text">{data.currentCollegeIfOther}</div>

                  <Label>Field of Study</Label>
                  <div className="text">{data.fieldOfStudy}</div>
                </DetailGrid>
              </CardContent>
            </DetailCard>
            <div></div>
          </div>
          <div className="column right">
            <h3>Contact via:</h3>
            <SocialLogosContainer>
              {data.email && (
                <Button
                  variant="outlined"
                  component="a"
                  href={`mailto:${data.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={
                    <MailOutlineIcon style={{ color: color.primaryBlue }} />
                  }
                >
                  Mail
                </Button>
              )}

              {data.twitterLink && (
                <Button
                  variant="outlined"
                  component="a"
                  href={`https://twitter.com/${data.twitterLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={
                    <img
                      src={twitterLogo}
                      alt={`${data.twitterLink} on Twitter`}
                    />
                  }
                >
                  @{data.twitterLink}
                </Button>
              )}
              {data.linkedInLink && (
                <Button
                  variant="outlined"
                  component="a"
                  href={`${data.linkedInLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={
                    <LinkedInIcon style={{ color: color.primaryBlue }} />
                  }
                >
                  LinkedIn
                </Button>
              )}
              {data.facebookLink && (
                <Button
                  variant="outlined"
                  component="a"
                  href={`https://www.facebook.com/${data.facebookLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={
                    <img
                      src={facebookLogo}
                      alt={`${data.facebookLink} on Facebook`}
                    />
                  }
                >
                  Facebook
                </Button>
              )}
            </SocialLogosContainer>
          </div>
        </Body>
      </AlumniDetailsContainer>
    </>
  );
};
export default AlumniDetail;
